import React from 'react'
import { Helmet } from 'react-helmet'
import './split.css'

const SplitLanding = () => {
  return (
    <>
      <Helmet>
        <title>Noah Settersten</title>
        <meta name="author" content="Noah Settersten" />
        <meta
          name="description"
          content="Noah Settersten is a Christ-follower and software developer."
        />
        <meta name="robots" content="index, follow" />
        <meta name="referrer" content="always" />

        <meta property="og:title" content="Noah Settersten" />
        <meta
          property="og:description"
          content="Noah Settersten is a Christ-follower and software developer."
        />
        <meta property="og:url" content="https://noahsettersten.com/" />

        <link
          rel="stylesheet"
          href="Noah%20Settersten_files/split.css"
          type="text/css"
          media="screen"
        />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
      </Helmet>
      <body id="fullsingle" class="page-template-page-fullsingle-split">
        <div class="fs-split">
          <div
            class="split-image"
            style={{
              backgroundImage: `url(${require('./images/portrait.jpg')})`,
              backgroundPosition: 'center center',
            }}
          ></div>

          <div class="split-content">
            <div class="split-content-vertically-center">
              <div class="split-intro">
                <h1>Noah Settersten</h1>
                <span class="tagline">
                  Follower of Jesus. Software Developer. Musician.
                </span>
              </div>

              <div class="split-bio">
                <p>
                  I'm a lover of Christ and his church. I build software during
                  the day and make music any time I can.
                </p>
                <p>
                  My heart is to see faithful, vibrant communities of
                  Christ-followers growing in grace, increasing in understanding
                  of God and his Word, and reaching their communities and
                  culture with the upside-down message of the gospel of grace.
                </p>
              </div>

              <div class="split-lists">
                <div class="split-list">
                  <h3>Connect</h3>
                  <ul>
                    <li>
                      <a href="http://thesetterstens.com/">Our Family</a>
                    </li>
                  </ul>
                </div>{' '}
                <div class="split-list">
                  <h3>Social</h3>
                  <ul>
                    <li>
                      <a href="https://linkedin.com/noahsettersten">LinkedIn</a>
                    </li>

                    <li>
                      <a href="https://github.com/noahsettersten">GitHub</a>
                    </li>
                  </ul>
                </div>{' '}
                <div class="split-list">
                  <h3>Work</h3>
                  <ul>
                    <li>
                      <a href="https://headway.io/">Headway</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="split-credit">
                <p>
                  ©2019 Noah Settersten -{' '}
                  <a href="https://onepagelove.com/split">Split Template</a> by{' '}
                  <a href="https://onepagelove.com/">One Page Love</a>
                </p>

                {/*
                  To edit this credit you can remove the CC3.0 license for only $5 here: https://onepagelove.com/split
                  this really helps contribute towards us developing more templates and means the world to me!
                  Cheers, Rob (@robhope)
                */}
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}

export default SplitLanding
